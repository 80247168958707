/* Globals type styling */
    h1, h2, h3, h4, h5, h6, p, label, a{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
        color: #FFFFFF;
    }
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(47deg, rgba(13, 9, 53, 1), rgba(16, 15, 100, .6) 80.71%),
                linear-gradient(127deg, rgba(7, 3, 28, 1), rgba(3, 2, 6, .7) 70.71%),
                linear-gradient(180deg, rgba(1, 0, 3, 1), rgba(6, 3, 18, .8) 90.71%),
                linear-gradient(317deg, rgba(19, 15, 109, 1), rgba(1, 0, 3, .7) 50.71%),
                linear-gradient(236deg, rgba(1, 0, 3, 1), rgba(7, 3, 28, .7) 70.71%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media screen and (max-width: 766px) {
  nav a.navbar-brand{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
}
.navbar-expand-lg {
    height: 80px;
    background: #010003;
}
.navbar-collapse {
 position: relative;
 height: 100%;
 flex-basis:100%;
 flex-grow:1;
 align-items:center
}
.navbar-collapse:active, .open>.navbar-collapse {
 background: #010003;
 position: relative;
 height: 100%;
 flex-basis:100%;
 flex-grow:1;
 align-items:center
}
.input-lg {
    left: 0;
    right: 50%;
    width: 100%;
    height: 48px;
    border-radius: 2px;
    background-color: #FFFFFF;
    border-bottom-color: #010003;
    border-left-color: #010003;
    border-right-color: #010003;
    border-top-color: #010003;
}
.input-lg::placeholder, input::placeholder, .input-lg::input {
            color: #010003 !important;
    opacity: 1;
}
input::placeholder{
            color: #010003 !important;
    opacity: 1;
}
.input-md {
    left: 0;
    right: 50%;
    width: 100%;
}
.dropdown {
    background: #010003;
    color: #FFFFFF;
    border-color: #FFFFFF;
    height: 79px;
    text-align: center;
}
/* Buttons */
.navbar-toggler {
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    color: #FFFFFF;
    border-color: transparent;
    position: relative;
    height: 64px;
    width: 64px;
    margin-left: 2px;
    margin-right: 2px;
    margin-top: -1px;
}
.navbar-toggler:hover, .navbar-toggler:focus, .navbar-toggler:active, .open>.dropdown-toggle.navbar-toggler {
    color: #FFFFFF;
    background: linear-gradient(#d3309dd9, #d3309dbf, #d3309da6, #d3309d8c, #d3309d73, #d3309d59, #d3309d40, #d3309d26);
    border-color: transparent;
}

.btn {
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    color: #FFFFFF;
    border-color: transparent;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
    color: #FFFFFF;
    background: linear-gradient(#d3309dd9, #d3309dbf, #d3309da6, #d3309d8c, #d3309d73, #d3309d59, #d3309d40, #d3309d26);
    border-color: transparent;
}

.btn-md{
    background: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF;
    width: 80%;
    height: 48px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
}
.btn-md:hover, .btn-md:focus, .btn-md:active, .btn-md.active{
    color: #FFFFFF;
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    border-color: #FFFFFF;
}

.btn-start-primary{
    background: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF;
    width: 80%;
    max-width: 384px;
    height: 48px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
}
.btn-start-primary:hover, .btn-start-primary:focus, .btn-start-primary.active {
    color: #FFFFFF;
    background: rgba(211, 46, 157, 0.65);
    border-color: #FFFFFF;
}

.btn-start-secondary{
    background: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF;
    width: 80%;
    max-width: 384px;
    height: 48px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-self: center;
}
.btn-start-secondary:hover, .btn-start-secondary:focus, .btn-start-secondary.active {
    color: #FFFFFF;
    background: rgba(93, 47, 134, 0.65);
    border-color: #FFFFFF;
}

.btn-info {
    background: #010003;
    color: #FFFFFF;
    border-color: transparent;
    height: 79px;
    text-align: center;
	top: 0;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open>.dropdown-toggle.btn-info {
    color: #FFFFFF;
    background: linear-gradient(#d3309dd9, #d3309dbf, #d3309da6, #d3309d8c, #d3309d73, #d3309d59, #d3309d40, #d3309d26);
    border-color: transparent;
}

.btn-info-outline {
    background: transparent;
    color: #010003;
    border-color: transparent;
    width: 48px;
    height: 48px;
    margin-left: -48px;
    margin-right: -48px;
}
.btn-info-outline:hover, .btn-info-outline:focus, .btn-info-outline:active, .btn-info-outline.active, .open>.btn-info-outline {
    color: #FFFFFF;
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    border-color: transparent;
}

.btn-help {
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    color: #FFFFFF;
    border-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    height: 79px;
    width: 64px;
}
.btn-help:hover, .btn-help:focus, .btn-help:active, .btn-help.active {
    color: #FFFFFF;
    background: linear-gradient(#d3309dd9, #d3309dbf, #d3309da6, #d3309d8c, #d3309d73, #d3309d59, #d3309d40, #d3309d26);
    border-color: transparent;
}

/*.btn-check {*/
/*    background: transparent;*/
/*    color: #FFFFFF;*/
/*    border-color: #FFFFFF;*/
/*    width: 128px;*/
/*    height: 24px;*/
/*    margin: 32px;*/
/*}*/

.btn-settings {
    background: linear-gradient(#00c4ff, #009CCC, #0091BD);
    color: #FFFFFF;
    border-color: transparent;
    position: relative;
    right: 0;
    margin-left: -36px;
}
/*  ///////////////// */
.link-primary {
    color: #00c4ff;
}
.link-primary:hover, .link-primary:focus, .link-primary:active {
    color: #5d2f86;
}

/* Containers */
.card {
    background-image: linear-gradient(#160954, #0F0638, #07031C, #020008);
    border-color: #00c4ff;
    border-width: 1px;
    border-radius: rounded;
    margin: 1px;
    justify-content: center;
    text-align: center;
    vertical-align: center;
}
.container{
    padding: 0;
    justify-content: center;
    text-align: center;
    vertical-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
}
.container-fluid {
    padding: 0;
    justify-content: center;
    text-align: center;
    vertical-align: center;
}
.col-l {
    padding-left:0;
    padding-right: 0;
    width: 50%;
    border-right: 1px solid #FFFFFF;
}
.col-r {
    padding-left:0;
    padding-right: 0;
    width: 50%;
    border-left: 1px solid #FFFFFF;
}

/* Tables */
.table > tbody > tr > td {
    white-space: nowrap;
     vertical-align: middle;
}
.table > thead > tr > th {
     white-space: nowrap;
}

/* Modal */
.modal {
    background-image: linear-gradient(#160954, #0F0638, #07031C, #020008);
    justify-content: center;
    text-align: center;
    vertical-align: center;
}